<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>My page</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                  <!-- 시리즈 학습일정 -->
                  <div style="width:100%;">
                    <div class="mypage_top_btn_wrap">
                    <!-- 포인트 잔액 -->
                    <div class="mypage_bank_btn_wrap">
                      <div class="mypage_bank_btn">
                        <div class="mypage_bank_h4_title">
                          <h4>포인트 잔액</h4>
                        </div>
                        <div class="mypage_bank_num">{{ balance }}</div>
                      </div>
                    </div>
                    <!-- //포인트 잔액 -->
                    <!-- 포인트 충전 -->
                    <div class="mypage_charge_btn_wrap">
                      <a href="#" @click.prevent="$router.replace({ name: 'my-page.point', query: { redirect: 'my-page' } })">
                        <div class="mypage_charge_btn">
                          <div class="mypage_charge_h4title">포인트 충전</div>
                        </div>
                      </a>
                    </div>
                    <!-- //포인트 충전 -->
                  </div>
                  <!-- 포인트 사용내역 -->
                  <div class="mypage_use_list">
                    <div class="mypage_use_title">
                      포인트 사용내역
                    </div>
                    <!--<ul @click.prevent="$router.replace({ name: 'notification', query: { redirect: 'my-page' } })" class="clickable">-->
                    <ul>
                      <li v-for="(point, idx) in pointHistories" v-bind:key="idx">
                        <a class="mypage_use_list_text" href="#">{{ point.series.name }} 수강료</a>
                        <div class="mypage_use_list_btn" v-text="point.enrollment_date"></div>
                      </li>
                    </ul>
                  </div>
                  </div>
                  <!--// 포인트 사용내역 -->
                  <!-- 알림 리스트 -->
                  <div class="mypage_notifi_list">
                    <div class="mypage_notifi_h4title">
                      <h4>알림</h4>
                    </div>
                    <ul>
                      <li>
                        <p style="font-weight: normal; font-size: 13px;">2021년 12월20일</p>
                        <a href="#">Moonkkang Talk 문깡톡 정식 서비스가 시작됩니다!</a>
                      </li>
                    </ul>
                  </div>
                  <!-- //알림 리스트 -->
                  <div class="mypage_myinfo_btn_wrap">
                    <a href="#" @click.prevent="$router.replace({ name: 'my-page.my-info', query: { redirect: 'my-page' } })">
                      <div class="mypage_myinfo_btn">
                        <div class="mypage_myinfo_h4title">
                          <h4>내 정보</h4>
                        </div>
                      </div>
                    </a>
                  </div>
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'my-page' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import { onMounted, ref } from '@vue/composition-api'
import User from '@/models/User'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'MyPage',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  },
  setup () {
    const balance = ref(0)
    const pointHistories = ref([])

    const loadMyProfile = async () => {
      try {
        const response = await User.myProfile()
        balance.value = response.data.amount
        pointHistories.value = response.data.enrollments
      } catch (e) {
        // Do Nothing Here ...
      }
    }

    onMounted(loadMyProfile)

    return {
      balance,
      pointHistories
    }
  }
}
</script>

<style scoped>

</style>
